import { getProvinces } from "@/api/address";
import { getLocal, setLocal } from "@/utils/storage";
let state = {
  provincesList: JSON.parse(getLocal("provinces")) || [],
};
let mutations = {
  // 省级行政信息存储
  M_provincesList(state, data) {
    // 持久化省份列表
    setLocal("provinces", JSON.stringify(data));
    state.provincesList = JSON.parse(getLocal("provinces"));
    // console.log(state.provincesList);
  },
};
let actions = {
  async A_provincesList(context) {
    let { code, data } = await getProvinces();
    // console.log(datas);
    if (code == 200) {
      //   通知状态机
      context.commit("M_provincesList", data);
    }
  },
};
let getters = {};
let modules = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
