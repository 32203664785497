import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// 引入仓库
import addressState from './addressState'
import userState from './userState'

export default new Vuex.Store({
  state: {
    
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    // 行政地区
    addressState,
    // 用户
    userState,
  }
})
