let serverUrl = {
  // 开发本机地址
  devLocal: "http://127.0.0.1:20060",
  // 开发局域地址
  devLocalArea: "http://192.168.1.196:20060",
  // 开发线上地址
  devNet: "http://39.103.193.158:80",
  // 打包发布地址
  build: "http://server.haosafer.com",
};

export default serverUrl;
