import request from "@/utils/request";

// 获取省份
export let getProvinces = () => {
  return request({
    url: "/basic/system/address/provinces",
  });
};

// 获取市区县及下级
export let getCitysNTowns = (params) => {
  return request({
    url: "/basic/system/address/sub-address/"+params,
    
  });
};

