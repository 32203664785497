import request from "@/utils/request";

//#公司部门
// 获取单位性质,类别
export let getDefinition = () => {
  return request({
    url: "/basic/definition/all",
  });
};

// 获取公司单位列表
export let getDeptList = () => {
  return request({
    url: "/basic/system/dept/list",
  });
};

// 获取获取公司部门类型
export let getDeptType = () => {
  return request({
    url: "/basic/system/dept/get-dept-types",
  });
};

// 获取树形公司部门列表
export let getDeptTree = (params) => {
  return request({
    url: "/basic/system/dept/dept-tree?" + params,
  });
};

// 获取公司部门人员列表
export let getDeptUser = (params) => {
  return request({
    url: "/basic/system/user/list?" + params,
  });
};

// 新建公司与部门
export let createDept = (data) => {
  return request({
    url: "/basic/system/dept",
    method: "post",
    data,
  });
};

//获取消防与维保公司信息
export let getDeptInfo = (params) => {
  return request({
    url: "/basic/system/dept/" + params,
  });
};

//删除公司部门
export let removeDept = (params) => {
  return request({
    url: "/basic/system/dept/" + params,
    method: "delete",
  });
};

//更新公司部门信息
export let updateDept = (data) => {
  return request({
    url: "/basic/system/dept/",
    method: "put",
    data,
  });
};

// 获取消防单位基本信息
export let getDeptFiretInfo = (params) => {
  return request({
    url: "/basic/system/dept/get-base-fire-info/" + params,
  });
};

//获取重点部位信息
export let getFireControlRoomInfo = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/getListByCondition",
    method: "post",
    data,
  });
};

// #人员角色
// 获取角色列表
export let getUserRole = () => {
  return request({
    url: "/basic/system/role/list",
  });
};

// 新建公司部门人员
export let createDeptUser = (data) => {
  return request({
    url: "/basic/system/user",
    method: "post",
    data,
  });
};
// 更新部门人员信息
export let updateDeptUser = (data) => {
  return request({
    url: "/basic/system/user",
    method: "put",
    data,
  });
};

//删除部门人员
export let removeUser = (params) => {
  return request({
    url: "/basic/system/user/" + params,
    method: "delete",
  });
};

// 获取消防工种人员与重点工种人员
export let getDeptPerson = (data) => {
  return request({
    url: "/basic/basicsDeptUserKeynote/getBasicsDeptUserKeynote",
    method: "post",
    data,
  });
};

// 添加重点工种人员
export let addDeptPerson = (data) => {
  return request({
    url: "/basic/basicsDeptUserKeynote/add",
    method: "post",
    data,
  });
};

// #资格证
// 上传从业资格证
export let uploadPersonKeynote = (data) => {
  return request({
    url: "/basic/basicsDeptUserKeynote/updateBasicsDeptUserKeynote",
    method: "post",
    data,
  });
};

// #培训演练
// 查询消防培训
export let queryFireTranings = (data) => {
  return request({
    url: "/basic/basicsDeptFireTraining/getBasicsDeptFireTrainings",
    method: "post",
    data,
  });
};

// 添加消防培训
export let addFireTranings = (data) => {
  return request({
    url: "/basic/basicsDeptFireTraining/add",
    method: "post",
    data,
  });
};

//上传消防培训结果
export let upLoadFireTraningRes = (data) => {
  return request({
    url: "/basic/basicsDeptFireTraining/updateBasicsDeptFireTraining",
    method: "post",
    data,
  });
};
// 查询消防演练
export let queryFireDrill = (data) => {
  return request({
    url: "/basic/basicsDeptFireDrill/getBasicsDeptFireDrills",
    method: "post",
    data,
  });
};

// 添加消防演练
export let addFireDrill = (data) => {
  return request({
    url: "/basic/basicsDeptFireDrill/add",
    method: "post",
    data,
  });
};

//上传消防演练结果
export let upLoadFireDrillRes = (data) => {
  return request({
    url: "/basic/basicsDeptFireDrill/updateBasicsDeptFireDrill",
    method: "post",
    data,
  });
};

// 获取消防机构业务信息
export let getFireNumList = (data) => {
  return request({
    url: "/basic/fireFightingFacilitySystem/getFireNumList",
    method: "post",
    data,
  });
};

// #设备类型
//获取设备类型信息
export let getDeviceType = (data) => {
  return request({
    url: "/basic/deviceTypeInfo/getDeviceTypeCensusList",
    method: "post",
    data,
  });
};
