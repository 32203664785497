<template>
  <button
    type="button"
    :class="`default-style ${btnType}`"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    btnType: {
      type: String,
      default: "common",
    },
  },
};
</script>

<style lang="scss" scoped>
.default-style {
  height: 34px;
  margin: 6px;
  line-height: 34px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  border-radius: 14px;
  &:hover {
    box-shadow: 0 0 8px 1px rgba(0, 255, 196, 0.5);
    color: rgba(0, 255, 195, 0.884);
    background-color: rgba(0, 255, 196, 0.25);
    transform: scale(1.02);
  }
}
.common {
  width: 98px;
  background: url("../assets/imgs/button/function-button.png") center center
    no-repeat;
  background-size: cover;
}

.long {
  width: 164px;
  background: url("../assets/imgs/button/function-button-long.png") center
    center no-repeat;
  background-size: cover;
}
</style>
