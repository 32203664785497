// 本地设值
export function setLocal(name, val) {
  return localStorage.setItem(name, val);
}
// 本地取值
export function getLocal(name) {
  return localStorage.getItem(name);
}
// 本地删值
export function removeLocal(name) {
  return localStorage.removeItem(name);
}
// 本地清空
export function clearLocal() {
  return localStorage.clear();
}


// 会话设值
export function setSession(name, val) {
  return sessionStorage.setItem(name, val);
}
// 会话取值
export function getSession(name) {
  return sessionStorage.getItem(name);
}
// 会话删值
export function removeSession(name) {
  return sessionStorage.removeItem(name);
}
// 会话清空
export function clearSession() {
  return sessionStorage.clear();
}

export default {
  setLocal,
  getLocal,
  removeLocal,
  clearLocal,
  setSession,
  getSession,
  removeSession,
  clearSession,
};
