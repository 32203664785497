import request from "@/utils/request";

// 获取建筑物列表
export let getBuildingList = (data) => {
  return request({
    url: "/basic/substructureBuilding/getBuildingListByCondition",
    method: "post",
    data,
  });
};

// 添加建筑物
export let addBuilding = (data) => {
  return request({
    url: "/basic/substructureBuilding/add",
    method: "post",
    data,
  });
};
// 修改建筑物
export let updateBuilding = (data) => {
  return request({
    url: "/basic/substructureBuilding/update",
    method: "put",
    data,
  });
};
// 删除建筑物
export let delBuilding = (data) => {
  return request({
    url: "/basic/substructureBuilding/removeById/" + data,
    method: "delete",
  });
};

// 获消控室列表
export let getFireRoomList = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/getListByCondition",
    method: "post",
    data,
  });
};
// 添加消控室
export let addFireRoom = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/add",
    method: "post",
    data,
  });
};
// 添加消控室图片
export let addFireRoomImg = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/addImg",
    method: "post",
    data,
  });
};
//删除消控室
export let removeFireRoom = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/removeById/" + data,
    method: "delete",
  });
};
//删除消控室图片
export let removeFireRoomImg = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/removeImgById/" + data,
    method: "delete",
  });
};
//修改消控室信息
export let editFireRoomInfo = (data) => {
  return request({
    url: "/basic/substructureFireControlRoomInfo/update",
    method: "put",
    data,
  });
};

// 获取重点部位信息
export let getKeyPartsInfo = (data) => {
  return request({
    url: "/basic/substructureKeyPartsInfo/BuildingKeyPartsVo",
    method: "post",
    data,
  });
};