<template>
  <div class="login-bg">
    <div class="login-handleView">
      <el-form
        :model="accountForm"
        ref="accountForm"
        :rules="loginRules"
        size="mini"
        hide-required-asterisk
      >
        <el-tabs v-model="currentActive">
          <el-tab-pane label="账号登录" name="account">
            <div class="login-phonebox">
              <i class="el-icon el-icon-user"></i>
              <el-form-item prop="acc">
                <input
                  type="text"
                  placeholder="请输入帐号"
                  v-model="accountForm.acc"
                />
              </el-form-item>
            </div>
            <div class="login-phonebox">
              <i class="el-icon el-icon-lock"></i>
              <el-form-item prop="pwd">
                <input
                  type="text"
                  placeholder="请输入密码"
                  v-model="accountForm.pwd"
                />
              </el-form-item>
            </div>
            <!-- 验证码 -->
            <div class="captcha">
              <div class="captcha-input-box">
                <el-form-item prop="capt">
                  <input
                    type="text"
                    placeholder="请输入验证码"
                    v-model="accountForm.capt"
                  />
                </el-form-item>
              </div>
              <div class="captcha-display-box">
                <canvas width="105" height="40" ref="loginCaptcha2"> </canvas>
                <span class="switch-code" @click="switchCaptcha((current = 2))"
                  >看不清,换一张</span
                >
              </div>
            </div>
            <button type="button" class="login-handle" @click="loginForm">
              登录
            </button>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
  </div>
</template>

<script>
import CaptchaMini from "captcha-mini";

export default {
  data() {
    return {
      // 默认激活tab
      currentActive: "account",
      accountForm: {
        // 账号
        acc: "",
        // 密码
        pwd: "",
        // 验证码
        capt: "",
      },
      loginRules: {
        acc: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { min: 3, max: 8, message: "长度在 3 到 8 个字符", trigger: "blur" },
        ],
        pwd: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 10, message: "长度在 6到 10 个字符", trigger: "blur" },
        ],
        capt: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // 验证码
    this.curCaptcha();
  },
  methods: {
    // 验证码
    curCaptcha() {
      let loginCaptcha1 = new CaptchaMini();
      loginCaptcha1.draw(this.$refs.loginCaptcha1, (r) => {
        console.log(r, "验证码1");
      });
      let loginCaptcha2 = new CaptchaMini();
      loginCaptcha2.draw(this.$refs.loginCaptcha2, (r) => {
        console.log(r, "验证码2");
      });
    },
    // 切换图片
    switchCaptcha(current) {
      current == 1
        ? this.$refs.loginCaptcha1.click()
        : this.$refs.loginCaptcha2.click();
    },
    // 登录按钮
    loginForm() {
      this.$refs.accountForm.validate(async (valid) => {
        if (valid) {
          // 登录调用
          this.$store.dispatch("userState/A_loginUserSave", this.accountForm);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin: 0 !important;
}
.login-bg {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("@/assets/imgs/login-bg.jpg") no-repeat center center;
  background-size: cover;
}
.login-handleView {
  width: 32.5rem;
  height: 21.25rem;
  padding: 3.5rem 1.8rem;
  background: url("../../assets/imgs/login-handle-view.png") no-repeat center
    center;
  background-size: cover;
}
.el-tab-pane {
  .el-icon {
    color: #fff;
    font-size: 2rem;
  }
  // 输入框统一样式
  input {
    height: 2.25rem;
    padding: 0 0.875rem;
    color: #fff;
    font-size: 0.875rem;
    line-height: 2.125rem;
    background-color: #26438f;
  }
  input::placeholder {
    color: #789dfa;
    font-size: 0.875rem;
  }
  // 电话
  .login-phonebox {
    margin-bottom: 1rem;
    width: 19.5rem;
    height: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: linear-gradient(135deg, transparent 0.625rem, #4473ea 0) center
      center;
    .el-icon-mobile-phone {
      font-size: 1.875rem;
      margin-left: 5px;
    }
    input {
      border: 1px solid transparent;
      width: 16rem;
    }
  }
  // 验证码
  .captcha {
    width: 19.5rem;
    height: 2.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .captcha-input-box {
      height: 100%;
      padding: 0 2px;
      display: flex;
      align-items: center;
      background-color: #4473ea;
      input {
        border: 1px solid transparent;
      }
    }

    .captcha-display-box {
      position: relative;
      .switch-code {
        position: absolute;
        right: -4.7rem;
        bottom: 0;
        color: #01eaff;
        font-size: 0.75rem;
        user-select: none;
        cursor: pointer;
      }
    }
  }
  .login-handle {
    width: 19.5rem;
    height: 2.5rem;
    background-color: #0fd7f1;
    color: #fff;
    border-radius: 5px;
    &:hover {
      background: #10b7c7;
    }
  }
}
// 重置
::v-deep .el-tabs__nav-wrap::after {
  background-color: transparent;
}
::v-deep .el-tabs__content {
  display: flex;
  justify-content: center;
}
::v-deep .el-tabs__item {
  padding: 0 0.625rem;
  color: #fff;
  font-size: 0.75rem;
}
::v-deep .el-tabs__item.is-active {
  font-size: 0.875rem;
  color: #fff;
}
::v-deep .el-tabs__item:hover {
  color: #efefef;
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
</style>
