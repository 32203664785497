import Vue from "vue";
import VueRouter from "vue-router";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject)
    return originalPush.call(this, location, resolve, reject);
  return originalPush.call(this, location).catch((e) => {});
};

Vue.use(VueRouter);

import Login from "@/views/login/Login.vue";
import Layout from "@/views/layout/Layout.vue";

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    meta: { path: "/login", title: "登录" },
    component: Login,
  },
  {
    path: "/home",
    component: Layout,
    meta: { path: "/home", title: "首页", icon: "house", isClose: true },
    children: [
      {
        path: "/",
        meta: { path: "/home", title: "首页", isClose: true },
        component: () => import("@/views/home/Home.vue"),
      },
    ],
  },

  // 单位
  {
    path: "/unit",
    component: Layout,
    meta: { path: "/unit", title: "单位管理", icon: "s-custom" },
    redirect: "/unit/fireControl/deptList",
    children: [
      {
        path: "fireControl",
        // 多级渲染页
        component: () => import("@/views/router-view-template.vue"),
        // 多级渲染页
        meta: { path: "/unit/fireControl", title: "消防单位组织架构" },
        redirect: "/unit/fireControl/deptList",
        children: [
          {
            path: "deptList",
            meta: { path: "/unit/fireControl/deptList", title: "部门管理列表" },
            component: () =>
              import("@/views/unit/fireControlChilds/deptList.vue"),
          },
          {
            path: "deptUserList",
            meta: {
              path: "/unit/fireControl/deptUserList",
              title: "人员管理列表",
            },
            component: () =>
              import("@/views/unit/fireControlChilds/deptUserList.vue"),
          },
          {
            path: "basicInformation",
            meta: {
              path: "/unit/fireControl/basicInformation",
              title: "基本信息",
            },
            component: () =>
              import("@/views/unit/fireControlChilds/basicInformation.vue"),
          },
          {
            path: "fireControlBasicInformation",
            meta: {
              path: "/unit/fireControl/fireControlBasicInformation",
              title: "消防基础信息",
            },
            component: () =>
              import(
                "@/views/unit/fireControlChilds/fireControlBasicInformation.vue"
              ),
          },
          {
            path: "basicInformationOfFireSafety",
            meta: {
              path: "/unit/fireControl/basicInformationOfFireSafety",
              title: "消防安全基本情况",
            },
            component: () =>
              import(
                "@/views/unit/fireControlChilds/basicInformationOfFireSafety.vue"
              ),
          },
          {
            path: "rulesOfFireSafety",
            meta: {
              path: "/unit/fireControl/rulesOfFireSafety",
              title: "消防单位规章制度建设",
            },
            component: () =>
              import("@/views/unit/fireControlChilds/rulesOfFireSafety.vue"),
          },
          {
            path: "FireSafetyOperation",
            meta: {
              path: "/unit/fireControl/FireSafetyOperation",
              title: "消防安全操作规程",
            },
            component: () =>
              import("@/views/unit/fireControlChilds/FireSafetyOperation.vue"),
          },
          {
            path: "fireTraining",
            meta: { path: "/unit/fireControl/fireTraining", title: "消防培训" },
            component: () =>
              import("@/views/unit/fireControlChilds/fireTraining.vue"),
          },
          {
            path: "fireDrill",
            meta: { path: "/unit/fireControl/fireDrill", title: "消防演练" },
            component: () =>
              import("@/views/unit/fireControlChilds/fireDrill.vue"),
          },
          {
            path: "evaluationOfFireControlUnit",
            meta: {
              path: "/unit/fireControl/evaluationOfFireControlUnit",
              title: "消防单位评价",
            },
            component: () =>
              import(
                "@/views/unit/fireControlChilds/evaluationOfFireControlUnit.vue"
              ),
          },
        ],
      },

      {
        path: "fireControlService",
        component: () => import("@/views/router-view-template.vue"),
        meta: {
          path: "/unit/fireControlService",
          title: "消防服务机构组织架构",
        },
        redirect: "/unit/fireControlService/deptList",
        children: [
          {
            path: "deptList",
            meta: {
              path: "/unit/fireControlService/deptList",
              title: "消防部门管理列表",
            },
            component: () =>
              import("@/views/unit/fireControlServiceChilds/deptList.vue"),
          },
          {
            path: "deptUserList",
            meta: {
              path: "/unit/fireControlService/deptUserList",
              title: "人员管理列表",
            },
            component: () =>
              import("@/views/unit/fireControlServiceChilds/deptUserList.vue"),
          },
          {
            path: "basicInformation",
            meta: {
              path: "/unit/fireControlService/basicInformation",
              title: "基本信息",
            },
            component: () =>
              import(
                "@/views/unit/fireControlServiceChilds/basicInformation.vue"
              ),
          },
          {
            path: "businessInformation",
            meta: {
              path: "/unit/fireControlService/businessInformation",
              title: "消防服务机构业务信息",
            },
            component: () =>
              import(
                "@/views/unit/fireControlServiceChilds/businessInformation.vue"
              ),
          },
          {
            path: "evaluationInformation",
            meta: {
              path: "/unit/fireControlService/evaluationInformation",
              title: "消防服务机构评价信息",
            },
            component: () =>
              import(
                "@/views/unit/fireControlServiceChilds/evaluationInformation.vue"
              ),
          },
        ],
      },
    ],
  },
  // 点位
  {
    path: "/point",
    component: Layout,
    meta: { path: "/point", title: "点位管理", icon: "location-information", isClose: true },
    children: [
      {
        path: "/",
        meta: { path: "/point", title: "点位管理", isClose: true },
        component: () => import("@/views/point/pointManage.vue"),
      },
    ],
  },
  // 建筑
  {
    path: "/building",
    component: Layout,
    meta: { path: "/building", title: "建筑管理", icon: "office-building" },
    redirect: "/building/building",
    children: [
      {
        path: "building",
        meta: { path: "/building/building", title: "建筑管理" },
        component: () => import("@/views/building/building.vue"),
      },
      {
        path: "buildings",
        meta: { path: "/building/buildings", title: "建筑群管理" },
        component: () => import("@/views/building/buildings.vue"),
      },
    ],
  },
  // 设备
  {
    path: "/device",
    component: Layout,
    meta: { path: "/device", title: "设备管理", icon: "set-up" },
    redirect: "/device/deviceType",
    children: [
      {
        path: "deviceType",
        meta: { path: "/device/deviceType", title: "设备类型管理" },
        component: () => import("@/views/device/deviceType.vue"),
      },
      {
        path: "deviceList",
        meta: { path: "/device/deviceList", title: "设备列表" },
        component: () => import("@/views/device/deviceList.vue"),
      },
    ],
  },
  // 运营
  {
    path: "/operate",
    component: Layout,
    meta: { path: "/operate", title: "运营服务", icon: "s-operation" },
    redirect: "/operate/patrolTasks",
    children: [
      {
        path: "patrolTasks",
        meta: { path: "/operate/patrolTasks", title: "智能巡检" },
        component: () => import("@/views/operate/patrolTasks.vue"),
      },
      {
        path: "troubleshooting",
        meta: { path: "/operate/troubleshooting", title: "故障处理" },
        component: () => import("@/views/operate/troubleshooting.vue"),
      },
      {
        path: "deviceDemarcate",
        meta: { path: "/operate/deviceDemarcate", title: "设备标定" },
        component: () => import("@/views/operate/deviceDemarcate.vue"),
      },
      {
        path: "deviceCheckout",
        meta: { path: "/operate/deviceCheckout", title: "设备检验" },
        component: () => import("@/views/operate/deviceCheckout.vue"),
      },
      {
        path: "maintenanceTasks",
        meta: { path: "/operate/maintenanceTasks", title: "设备维修" },
        component: () => import("@/views/operate/maintenanceTasks.vue"),
      },
      {
        path: "pointManage",
        meta: { path: "/operate/pointManage", title: "点位管理" },
        component: () => import("@/views/operate/pointManage.vue"),
      },
      {
        path: "CRTDemarcate",
        meta: { path: "/operate/CRTDemarcate", title: "CRT标定" },
        component: () => import("@/views/operate/CRTDemarcate.vue"),
      },
      {
        path: "alarmNotes",
        meta: { path: "/operate/alarmNotes", title: "报警记录" },
        component: () => import("@/views/operate/alarmNotes.vue"),
      },
    ],
  },
  // 通知
  {
    path: "/notice",
    component: Layout,
    meta: { path: "/notice", title: "通知管理", icon: "message-solid" },
    redirect: "/notice/patrolNotice",
    children: [
      {
        path: "patrolNotice",
        meta: { path: "/notice/patrolNotice", title: "智能巡检通知" },
        component: () => import("@/views/notice/patrolNotice.vue"),
      },
      {
        path: "deviceRepairNotice",
        meta: { path: "/notice/deviceRepairNotice", title: "设备维修通知" },
        component: () => import("@/views/notice/deviceRepairNotice.vue"),
      },
      {
        path: "deviceCheckNotice",
        meta: { path: "/notice/deviceCheckNotice", title: "设备检验通知" },
        component: () => import("@/views/notice/deviceCheckNotice.vue"),
      },
      {
        path: "deviceDemarcateNotice",
        meta: { path: "/notice/deviceDemarcateNotice", title: "设备标定通知" },
        component: () => import("@/views/notice/deviceDemarcateNotice.vue"),
      },
      {
        path: "createPointNotice",
        meta: { path: "/notice/createPointNotice", title: "新建点位通知" },
        component: () => import("@/views/notice/createPointNotice.vue"),
      },
      {
        path: "templateNotice",
        meta: { path: "/notice/templateNotice", title: "通知模板" },
        component: () => import("@/views/notice/templateNotice.vue"),
      },
      {
        path: "noticeMethod",
        meta: { path: "/notice/noticeMethod", title: "通知策略" },
        component: () => import("@/views/notice/noticeMethod.vue"),
      },
    ],
  },
  // ITD
  {
    path: "/ITD",
    component: Layout,
    meta: { path: "/ITD", title: "ITD数据中心", icon: "data-analysis" },
    redirect: "/ITD/laws",
    children: [
      {
        path: "laws",
        meta: { path: "/ITD/laws", title: "法律法规" },
        component: () => import("@/views/ITD/laws.vue"),
      },
      {
        path: "tradePolicy",
        meta: { path: "/ITD/tradePolicy", title: "行业政策" },
        component: () => import("@/views/ITD/tradePolicy.vue"),
      },
      {
        path: "rules",
        meta: { path: "/ITD/rules", title: "规章制度" },
        component: () => import("@/views/ITD/rules.vue"),
      },
      {
        path: "how-to's",
        meta: { path: "/ITD/how-to's", title: "操作教程" },
        component: () => import("@/views/ITD/how-to's.vue"),
      },
      {
        path: "fireControlTrain",
        meta: { path: "/ITD/fireControlTrain", title: "消防培训" },
        component: () => import("@/views/ITD/fireControlTrain.vue"),
      },
      {
        path: "ITDhandle",
        meta: { path: "/ITD/ITDhandle", title: "操作" },
        component: () => import("@/views/ITD/ITDhandle.vue"),
      },
    ],
  },
  // 系统
  {
    path: "/system",
    component: Layout,
    meta: { path: "/system", title: "系统管理", icon: "setting" },
    redirect: "/system/accountManage",
    children: [
      {
        path: "accountManage",
        meta: { path: "/system/accountManage", title: "账号管理" },
        component: () => import("@/views/system/accountManage.vue"),
      },
      // {
      //   path: "roleManage",
      //   meta: { path: "/system/roleManage", title: "角色管理" },
      //   component: () => import("@/views/system/roleManage.vue"),
      // },
      {
        path: "systemLog",
        meta: { path: "/system/systemLog", title: "系统日志" },
        component: () => import("@/views/system/systemLog.vue"),
      },
      {
        path: "changePassword",
        meta: { path: "/system/changePassword", title: "修改密码" },
        component: () => import("@/views/system/changePassword.vue"),
      },
      {
        path: "titleSet",
        meta: { path: "/system/titleSet", title: "标题设置" },
        component: () => import("@/views/system/titleSet.vue"),
      },
    ],
  },
  // 建筑管理信息

  {
    path: "/buildingInfo",
    component: Layout,
    meta: {noMenu:true },
    children: [
      {
        path: "buildingInfo",
        meta: { path: "/buildingInfo/buildingInfo", title: "基本信息" },
        component: () => import("@/views/buildingInfo/buildingInfo.vue"),
      },
      {
        path: "fireControlRoom",
        meta: { path: "/buildingInfo/fireControlRoom", title: "消控室信息" },
        component: () => import("@/views/buildingInfo/fireControlRoom.vue"),
      },
      {
        path: "point",
        meta: { path: "/buildingInfo/point", title: "重点部位信息" },
        component: () => import("@/views/buildingInfo/point.vue"),
      },
    ],
  },

  // 其他操作页面
  {
    path: "/otherHandle",
    component: Layout,
    meta: {noMenu:true },
    children: [
      {
        path: "addbuilding",
        meta: { path: "/otherHandle/addbuilding", title: "新增建筑" },
        component: () => import("@/views/otherHandle/addBuilding.vue"),
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/views/404/404.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
