<template>
  <router-link :class="`router-default ${buttonType} `" :to="path">
    <span :class="path == this.$route.path ? 'router-active' : ''">
      <slot></slot>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    buttonType: {
      type: String,
      default: "common",
    },
    path: {
      type: String,
      required: true,
    },
  },
  created() {
    // console.log(this.$route);
  },
};
</script>

<style lang="scss" scoped>
// 默认
.router-default {
  display: inline-block;
  border: 0;
  padding: 0 10px;
  margin-right: 20px;
  line-height: 52px;
  text-align:center;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
}
span {
  color: #fff;
}
// 激活
.router-active {
  color: transparent;
  background-image: linear-gradient(#fff, rgba(0, 255, 196));
  background-clip: text;
}
//   标准样式
.common {
  width: 148px;
  height: 52px;
  background: url("../assets/imgs/button/router-button-common.png") no-repeat center
    center;
  background-size: cover;
}
.big{
  width: 186px;
  height: 52px;
    background: url("../assets/imgs/button/router-button-big.png") no-repeat center
    center;
  background-size: cover;
}

</style>
