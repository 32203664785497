<style lang="scss" scoped>
.views {
  width: 100%;
  min-width: 1920px;
  height: 100%;
  position: relative;
  background: linear-gradient(#07233e, #020917);
  .alert-mess-box {
    display: flex;
    div {
      width: 50%;
      margin-bottom: 10px;
      // background: #020917;
      color: #5effff;
      font-size: 14px;
    }
  }
  //   顶部信息
  .header {
    width: 100%;
    height: 30%;
    min-height: 7.5rem;
    padding-top: 2.5rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    color: #fff;
    background: url("../../assets/imgs/header.png") center -5.75rem no-repeat;
    background-size: 100% 20rem;
    // 当前时间
    .current-time {
      flex: 3.5;
      text-align: right;
    }
    // 用户下拉
    .admin {
      flex: 3.5;
      display: flex;
      justify-content: flex-end;
      .el-dropdown {
        margin-right: 6rem;
        .el-dropdown-link {
          color: #fff;
          display: flex;
          align-items: center;
          cursor: pointer;
          .icon-admin {
            border: 1px solid #06d5f5;
            border-radius: 50%;
            margin-right: 10px;
          }
        }
      }
    }
    // 标题
    .program-title {
      flex: 2.5;
      margin: 0 22.5rem;
      margin-top: -1rem;
      padding-left: 1.5rem;
      text-align: center;
      font-weight: bold;
      font-size: 1.875rem;
      color: transparent;
      background-image: linear-gradient(#fff, #9ef);
      background-clip: text;
    }
  }
  // 导航
  .nav-content {
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 500;
    .el-menu {
      padding: 40px 0;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 100%;
      border: none;
    }
    .nav-btn {
      position: absolute;
      top: 0;
      right: -29px;
      width: 30px;
      height: 30px;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 24px;
      text-align: center;
      background: rgba(255, 255, 255, 0.212);
      cursor: pointer;
    }
    // &:not(.el-menu--collapse) {
    //   width: 200px;
    //   min-height: 400px;
    // }
    // .el-menu .el-submenu__title:hover,
    // .el-menu-item:focus,
    // .el-menu-item:hover {
    //   background-color: cornflowerblue !important;
    // }
  }
  // 内容
  .container {
    padding: 0 80px;
    width: 100%;
    height: 85%;
    position: absolute;
    top: 15%;
    overflow: hidden;
    box-sizing: border-box;
    background: url("../../assets/imgs/view-bg.png") center center no-repeat;
    background-size: 100% 100%;
    // 水平导航
    .horizon-nav {
      display: flex;
      padding: 20px 0;
      border-bottom: 2px solid rgba(9, 234, 246);
      margin-bottom: 20px;
    }
    .container-footer {
      height: 40px;
      width: 100%;
    }
  }
}
// 用户下拉框最小宽度限定
.el-dropdown-menu {
  min-width: 6.4rem;
}
</style>
<template>
  <div class="views">
    <!-- 顶部信息 -->
    <div class="header">
      <div class="current-time">{{ ctime }}</div>
      <div class="program-title">{{ appTitle }}</div>
      <div class="admin">
        <el-dropdown @command="loginOut">
          <span class="el-dropdown-link">
            <icon
              iconName="user-solid"
              iconColor="#06D5F5"
              iconSize="20"
              class="icon-admin"
            ></icon>
            欢迎您！{{ "管理员"
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="loginOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 导航 -->

    <!-- v-show="false" -->
    <div class="nav-content">
      <el-menu
        :default-active="$route.path"
        background-color="#052339"
        text-color="#fff"
        unique-opened
        router
        :collapse="menuShow"
      >
        <menu-item
          v-for="menu in menuList"
          :key="menu.path"
          :item="menu"
        ></menu-item>
      </el-menu>
      <div class="nav-btn" @click="navCheckHandle">
        <icon :iconName="handleIcon" iconColor="#07D7F6"></icon>
      </div>
    </div>

    <!-- 报警弹框 -->
    <alarm-modal :modalShow.sync="alertModalShow">
      <section class="alert-mess-box">
        <div>设备id:{{ alertResObj.deviceId }}</div>
        <div>设备地址:{{ alertResObj.installAddress }}</div>
        <div>设备状态:{{ alertResObj.deviceStatus }}</div>
        <div>责任人:{{ alertResObj.liablePeopleName }}</div>
        <div>责任人联系电话:{{ alertResObj.liablePeoplePhone }}</div>
      </section>
      <template #footer>
        <el-button
          @click="$router.push('/operate/alarmNotes')((alertModalShow = false))"
          >查看详情</el-button
        >
      </template>
    </alarm-modal>
    <!-- 视图内容 -->
    <div class="container">
      <div style="overflow: auto; width: 100%; height: 100%">
        <router-view></router-view>
      </div>
      <div class="container-footer"></div>
    </div>
  </div>
</template>

<script>
//服务器
import { currentServer } from "@/utils/request";
// 插件
import dayjs from "dayjs";
// 组件
import Icon from "@/components/icon-component.vue";
import routerButton from "@/components/router-button.vue";
import alarmModal from "@/components/alarm-modal.vue";
import menuItem from "@/components/menu-item.vue";
// 工具
import {
  getSession,
  setSession,
  setLocal,
  getLocal,
  removeLocal,
  removeSession,
} from "@/utils/storage";
// 接口
import { userInfo } from "@/api/user";
import { getDeptInfo, getDeptList } from "@/api/depUnit";
import { getBuildingList } from "@/api/building";
import { getDeviceTypeList } from "@/api/devices";

import { getSystemTitle } from "@/api/system";

export default {
  components: {
    Icon,
    routerButton,
    alarmModal,
    menuItem,
  },
  data() {
    return {
      appTitle: "新孚好安全管理平台",
      timeRunner: null,
      ctime: "",
      handleIcon: "arrow-left",
      // 返回建筑列表按钮
      returnBuilding: false,
      // socket对象
      alertWebsocket: null,
      // 报警响应对象
      alertResObj: {
        liablePeopleName: "",
        liablePeoplePhone: "",
        deviceStatus: "",
        installAddress: "",
        deviceId: "",
      },
      // 报警弹框控制
      alertModalShow: false,
      // 导航
      menuList: [],
      menuShow: false,
    };
  },
  computed: {},
  async created() {
    this.menuList = this.$router.options.routes;
    // 标题
    let titleRes = await getSystemTitle();
    // console.log(titleRes);
    if (titleRes.code === 200) {
      this.appTitle = titleRes.message;
    }
    setLocal("appTitle", this.appTitle);

    // 清除重复时间定时器
    clearInterval(this.timeRunner);
    this.timeRunner = null;
    // 运行当前时间函数
    this.currentTime();
    // 公司_部门类别存入
    setLocal(
      "xf_deptType",
      JSON.stringify([
        { type: "ADMIN", name: "管理部门", id: 1 },
        { type: "MAINTAINER", name: "维保部门", id: 2 },
        { type: "END_USER", name: "终端用户部门，园区", id: 3 },
      ])
    );
    // 建筑物数据存储
    let buildingRes = await getBuildingList({
      basePage: { pageNum: 1, pageSize: 999999 },
      // deptId: JSON.parse(getLocal("userInfo")).userPo.deptId,
    });
    setLocal("buildingList", JSON.stringify(buildingRes.data.list));
    // 省级信息存入
    this.$store.dispatch("addressState/A_provincesList");
    // 用户信息
    let res = await userInfo();
    // console.log(res);
    if (res.code == 200) {
      setLocal("userInfo", JSON.stringify(res.data));
      // 公司部门信息
      let deptRes = await getDeptInfo(res.data.userPo.deptId);
      setLocal("deptInfo", JSON.stringify(deptRes.data));

      // 公司部门列表
      let deptListRes = await getDeptList();
      let deptMap = {};
      deptListRes.data.forEach((element) => {
        deptMap["" + element.deptId] = element.deptName;
      });

      setLocal("deptMap", JSON.stringify(deptMap));
      // console.log(deptListRes);
      setLocal("deptList", JSON.stringify(deptListRes.data));
    }

    // 设备类型列表
    let deviceTypeInfo = await getDeviceTypeList();
    let deviceTypeMap = {};
    let deviceTypeIconMap = {};
    deviceTypeInfo.data.list.forEach((element) => {
      deviceTypeMap["" + element.id] = element.name;
      deviceTypeMap[element.name] = deviceTypeMap["" + element.id];
      deviceTypeIconMap["" + element.id] = element.iconImg;
    });

    setLocal("deviceTypeMap", JSON.stringify(deviceTypeMap));
    setLocal("deviceTypeIconMap", JSON.stringify(deviceTypeIconMap));

    // 报警socket对象
    // console.log(JSON.parse(getLocal("xfhli")).token.split(" "));
    let websocketServer = currentServer;
    if (websocketServer.includes("http")) {
      websocketServer = websocketServer.replace("http", "ws");
    } else if (websocketServer.includes("https")) {
      websocketServer = websocketServer.replace("https", "wss");
    }
    // console.log(websocketServer);
    this.alertWebsocket = new WebSocket(
      `${websocketServer}/basic/webSocket/${
        JSON.parse(getLocal("userInfo")).userPo.deptId
      }/${JSON.parse(getLocal("userInfo")).userPo.userId}`
    );
    // 开启状态
    this.alertWebsocket.onopen = () => {
      // console.log("open");
      this.alertWebsocket.send(JSON.stringify({ one: "1" }));
    };
    // 响应信息
    this.alertWebsocket.onmessage = (res) => {
      // console.log(res);
      if (res.data) {
        try {
          this.alertResObj = JSON.parse(res.data);
          this.alertModalShow=true
        } catch (error) {
          // console.log("检查数据格式:", error);
        }
      }
    };
    // 报错
    this.alertWebsocket.onerror = (error) => {
      console.log("websocket err ", error);
      setTimeout(() => {
        this.alertWebsocket.close();
      }, 60000);
    };
    this.alertWebsocket.onclose = (res) => {
      console.log(res, "关闭链接");
    };
  },
  mounted() {
    this.$eventBus.$on("appTitle", (val) => {
      this.appTitle = val;
      setLocal("appTitle", val);
    });
  },
  beforeDestroy() {
    clearInterval(this.timeRunner);
    this.timeRunner = null;
    this.alertWebsocket.close();
  },
  watch: {},
  methods: {
    // 当前时间
    currentTime() {
      function getWeekDate(time = new Date()) {
        let weeks = new Array(
          "星期日",
          "星期一",
          "星期二",
          "星期三",
          "星期四",
          "星期五",
          "星期六"
        );

        let date = new Date(time);
        let day = date.getDay();
        return weeks[day];
      }
      // 分钟与秒数
      // let curMinute = 0;
      let curSecound = 0;

      this.timeRunner = setInterval(async () => {
        let time = dayjs().format(
          "YYYY年MM月DD日 " + getWeekDate() + " hh:mm:ss"
        );
        this.ctime = time;
        // 轮询更新token 当天时间等于 23点55分0秒时更新
        if (
          dayjs().hour() == 23 &&
          dayjs().minute() == 55 &&
          dayjs().second() == 0
        ) {
          this.$store.dispatch(
            "userState/A_loginUserSave",
            JSON.parse(getSession("LA"))
          );
        }
      }, 1000);
    },
    // 导航切换
    navCheckHandle() {
      if (this.handleIcon == "arrow-left") {
        this.handleIcon = "arrow-right";
        this.menuShow = true;
      } else {
        this.menuShow = false;
        this.handleIcon = "arrow-left";
      }
    },
    // 退出登录
    loginOut(command) {
      if (command === "loginOut") {
        removeLocal("xfhli");
        removeLocal("deptInfo");
        removeLocal("userInfo");
        removeSession("LA");
        removeSession("OSS");
        if (this.alertWebsocket) {
          this.alertWebsocket.close();
          this.alertWebsocket.onclose = (res) => {
            console.log(res);
          };
        }
        this.$router.push("/login");
      }
    },
  },
};
</script>
