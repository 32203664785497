<template>
  <div class="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" >
.app{
  width: 100%;
  height: 100%;
}


// 自定义滚动条
::-webkit-scrollbar {
    /* 对应纵向滚动条的宽度 */
    width: 1.6vh;
    /* 对应横向滚动条的宽度 */
    height: 1.2vw;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
    background-color: rgb(0, 145, 202);
    border-radius: 32px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 32px;
}
</style>