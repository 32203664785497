import axios from "axios";

import { getLocal } from "./storage";
import serviceUrl from "./serviceUrl";
// 当前服务器地址
export let currentServer = serviceUrl.build;
// 接口
const request = axios.create({
  baseURL: currentServer,
  timeout: 30000,
});

//请求拦截
request.interceptors.request.use(
  (config) => {
    let serverReturnAcc = JSON.parse(getLocal("xfhli"));
    if (serverReturnAcc && !("/basic/login" === config.url)) {
      config.headers["Authorization"] = serverReturnAcc.token;
    }
    return config;
  },

  (errlog) => {
    return Promise.reject(errlog);
  }
);

//响应拦截
request.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (errlog) => {
    return Promise.reject(errlog);
  }
);

export default function (
  //接口参数
  { url, method, data, params, contentType, file }
) {
  return request({
    url: url,
    method: method,
    data: data || {},
    params: params || "",
    headers: {
      "Content-Type": contentType || "application/json",
    },
  });
}
