// vue生态
import Vue from "vue";
import router from "./router";
import store from "./store";
import App from "./App.vue";
// css重置
import "@/assets/css/normalize.css";
import "@/assets/css/reset.css";
import "./assets/css/heartBeat.css";
// 组件库
import dataV from "@jiaminghi/data-view";
Vue.use(dataV);
// ui库
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
// 组件标题替换
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);

Vue.config.productionTip = false;

// 地图密钥
window._AMapSecurityConfig = {
  // 安全密钥
  securityJsCode: "291c8f5326539a66bae3199473f69481",
};

// 全局OSS对象
import OSS from "ali-oss";
import { getOssAccess } from "@/api/oss";

let ossMes = null;
(async () => {
  let res = await getOssAccess();
  // console.log(res);
  ossMes = res.data;
  window.OSSclient = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: "oss-cn-chengdu",
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: ossMes.accessKeyId,
    accessKeySecret: ossMes.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: ossMes.securityToken,
    // 填写Bucket名称。
    bucket: "haosafer",
    refreshSTSToken: async () => {
      // 向您搭建的STS服务获取临时访问凭证。
      const { data } = await getOssAccess();
      return {
        accessKeyId: data.accessKeyId,
        accessKeySecret: data.accessKeySecret,
        stsToken: data.securityToken,
      };
    },
    // 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: 1800000,
  });
})();

// 事件总线实例
Vue.prototype.$eventBus = new Vue();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// 全局组件
import fnButton from "@/components/function-button.vue";
Vue.component("fnButton", fnButton);

// 登录拦截
// router.beforeEach((to, from, next) => {
//   if (sessionStorage.getItem("LA")) {
//     next();
//   } else {
//     next({ path: "/login" });
//   }
// });
