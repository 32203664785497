import request from "@/utils/request";

// 登录
export let userLogin =(data)=>{
    return request({
        url:"/basic/login",
        method:"post",
        data:data,
    })
}
// 获取用户信息
export let userInfo =()=>{
    return request({
        url:"/basic/get-info",
    })
}
