import request from "@/utils/request";

// #账号

// 获取账号列表
export let getUserList = (params) => {
  return request({
    url: "/basic/system/user/list" + params,
  });
};
// 添加账号
export let addUser = (data) => {
  return request({
    url: "/basic/system/user",
    method: "post",
    data,
  });
};
// 修改账号
export let editUser = (data) => {
  return request({
    url: "/basic/system/user",
    method: "put",
    data,
  });
};
// 删除账号
export let removeUser = (params) => {
  return request({
    url: "/basic/system/user/" + params,
    method: "delete",
  });
};
// 重置账号密码
export let resetPassword = (data) => {
  return request({
    url: "/basic/system/user/reset-password",
    method: "put",
    data,
  });
};

// 修改账号权限
export let authRole = (data) => {
  return request({
    url: "/basic/system/user/auth-roleVo",
    method: "put",
    data,
  });
};

// #角色

// 获取角色列表
export let getUserRoleList = () => {
  return request({
    url: "/basic/system/role/list/",
  });
};
// 获取当前角色权限列表
export let getRoleAuthList = (params) => {
  return request({
    url: "/basic/system/role/" + params,
  });
};

// 创建账号角色权限
export let createUserRole = (data) => {
  return request({
    url: "/basic/system/role",
    method: "post",
    data,
  });
};

// 角色权限修改
export let editUserRolePermission = (data) => {
  return request({
    url: "/basic/system/role",
    method: "put",
    data,
  });
};
// 删除角色权限
export let removeUserRole = (data) => {
  return request({
    url: "/basic/system/role/" + data,
    method: "delete",
  });
};

// #日志

//获取系统日志
export let getsystemLog = (params) => {
  return request({
    url: "/basic/monitor/op-log/list?" + params,
  });
};

// #标题
// 查询当前单位标题
export let getSystemTitle = () => {
  return request({
    url: "/basic/basicsDeptOnlyConfig/getDeptTitleList",
  });
};

// 修改单位标题
export let editSystemTitle = (data) => {
  return request({
    url: "/basic/basicsDeptOnlyConfig/saveOrUpdate",
    method: "post",
    data,
  });
};

// #路由菜单
// 获取路由菜单

export let getUserMenu = () => {
  return request({
    url: "/basic/system/menu/menu-tree",
  });
};
