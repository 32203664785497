<template>
  <fragment>
    <template v-if="item.children">
      <template v-if="!item.meta.noMenu">
        <!--层级无子级 -->
        <template v-if="item.children.length <= 1">
          <el-menu-item :index="item.meta.path">
            <template v-if="item.meta.icon">
              <icon-component :iconName="item.meta.icon"></icon-component>
            </template>
            <span slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
        </template>
        <!-- 层级有子级 -->
        <el-submenu v-else :index="item.meta.path">
          <!-- 层级标题 -->
          <template slot="title">
            <template v-if="item.meta.icon">
              <icon-component :iconName="item.meta.icon"></icon-component>
            </template>
            <span slot="title">
              {{ item.meta.title }}
            </span>
          </template>
          <template v-for="child in item.children">
            <!-- 子级内容递归 -->
            <navigation-item
              v-if="child.children && child.children.length > 0"
              :item="child"
              :key="child.path"
            />
            <el-menu-item v-else :key="child.path" :index="child.meta.path">
              {{ child.meta.title }}
            </el-menu-item>
          </template>
        </el-submenu>
      </template>
    </template>
  </fragment>
</template>

<script>
import iconComponent from "./icon-component.vue";
export default {
  components: { iconComponent },
  name: "navigationItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>
