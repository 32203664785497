import router from "@/router";
import { Message } from "element-ui";
import { userLogin } from "@/api/user";
import { getLocal, setLocal, setSession, getSession } from "@/utils/storage";

let state = {
  // 当前用户
  currentUser: JSON.parse(getLocal("xfhli")) || {},
  //   账号密码
  loginAccount: JSON.parse(getSession("LA")) || {},
};
let mutations = {
  // 登录用户存储
  //   data=接口数据,value=账号密码数据
  M_loginUserSave(state, { data, value }) {
    // 解决刷新丢失
    setLocal("xfhli", JSON.stringify(data));
    setSession("LA", JSON.stringify(value));
    state.currentUser = JSON.parse(getLocal("xfhli"));
    state.loginAccount = JSON.parse(getSession("LA"));
  },
};
let actions = {
  // 登录
  async A_loginUserSave(context, value) {
    let { code, data } = await userLogin({
      password: value.pwd,
      username: value.acc,
    });
    // console.log(code, data);
    // 成功回调
    if (code == 200) {
     
      if (getSession("LA")) {
        context.commit("M_loginUserSave", { data, value });
      }else{
        context.commit("M_loginUserSave", { data, value });
        Message({
          message:"登录成功",
          type: "success",
        });
        router.push("/home")
      }
      
    } else {
      Message.error("登陆失败,状态码："+code);
    }
  },
};
let getters = {};
let modules = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  modules,
  actions,
};
