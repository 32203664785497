import request from "@/utils/request";

// 下载xlsx文件
export let fileDownLoad = (params) => {
  return request({
    url: "/basic/common/download",
    params,
  });
};
// 获取园区公司列表
export let getCompanyList = () => {
  return request({
    url: "/basic/system/dept/get-by-maintainer-dept",
  });
};

// 获取设备静态动态属性
export let getDeviceChangeInfoDtos = (params) => {
  return request({
    url: "/basic/deviceInfo/getDeviceChangeInfoDtos/" + params,
  });
};
// 获取设备动态图表属性数据
export let getDeviceHistoryChangeProps = (data) => {
  return request({
    url: "/basic/deviceTypeInfo/getDeviceTypeList/" + data,
    method: "post",
  });
};

// 获取设备列表
export let getDeviceList = (data) => {
  return request({
    url: "/basic/deviceInfo/getDeviceListByCondition",
    method: "post",
    data,
  });
};

// 获取设备类型列表
export let getDeviceTypeList = (data) => {
  return request({
    url: "/basic/deviceTypeInfo/getDeviceTypeList",
    method: "post",
    data,
  });
};

// 添加设备
export let addDeviceInfo = (data) => {
  return request({
    url: "/basic/deviceInfo/add",
    method: "post",
    data,
  });
};

// 修改设备
export let updateDeviceInfo = (data) => {
  return request({
    url: "/basic/deviceInfo/update",
    method: "put",
    data,
  });
};

// 删除设备
export let removeDevice = (data) => {
  return request({
    url: "/basic/deviceInfo/removeById/" + data,
    method: "delete",
  });
};

// 批量导出设备excel
export let exportDeviceList = (data) => {
  return request({
    url: "/basic/deviceInfo/exportDeviceList",
    method: "post",
    data,
  });
};

// 批量导入设备excel
export let importDeviceList = (data, contentType) => {
  return request({
    url: "/basic/deviceInfo/importDeviceList",
    method: "post",
    data,
    contentType,
  });
};

// 设备历史记录
export let getDeviceHistory = (data) => {
  return request({
    url: "/basic/deviceHistoryValue/getListByCondition",
    method: "post",
    data,
  });
};
// 设备历史记录状态
export let getDeviceHistoryStatus = (data) => {
  return request({
    url: "/basic/deviceHistoryStatus/getListByCondition",
    method: "post",
    data,
  });
};


// 处理历史记录状态
export let handleDeviceHistoryStatus = (data) => {
  return request({
    url: "/basic/deviceHistoryStatus/update",
    method: "put",
    data,
  });
};

