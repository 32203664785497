<template>
  <div
    :class="`modal-background ${modalShow ? 'isModalActive' : 'isClose'}`"
    @click="handleClose($event)"
  >
    <div class="modal-style">
      <!-- 头部 -->
      <div class="header">
        <div class="modal-header-title">
          <icon-component
            iconName="warning"
            iconColor="#f00"
            :iconSize="108"
          ></icon-component>
        </div>
        <!-- 关闭按钮 -->
        <i
          class="el-icon-iconName el-icon-close"
          @click="handleClose($event)"
        ></i>
      </div>
      <!-- 内容 -->
      <div class="modal-content" :style="`height:${bodyHeight}%`">
        <slot></slot>
      </div>
      <div class="modal-footer">
        <!-- 底部具名 -->
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import IconComponent from "@/components/icon-component.vue";
export default {
  components: {
    IconComponent,
  },
  props: {
    modalShow: {
      type: Boolean,
      default: false,
    },
    bodyHeight: {
      type: Number,
    },
  },
  data() {
    return {
      // 避免直接修改父组件值
      modalState: this.modalShow,
    };
  },
  methods: {
    // 蒙层遮罩关闭
    handleClose(e) {
      return e.target === e.currentTarget
        ? this.$emit("update:modalShow", (this.modalState = false))
        : "";
      console.log(this.modalShow, this.modalState);
    },
  },
};
</script>

<style lang="scss" scoped>
// 重置表单输入部分样式
::v-deep .el-form-item__label {
  color: #fff;
}
::v-deep .el-input__inner {
  border-radius: 2px;
  border: 1px solid rgba(15, 221, 241, 0.6);
  color: #fff;
  background: transparent;
}

// 模态框
.isClose {
  display: none;
}
.isModalActive {
  display: block;
}
// 蒙层
.modal-background {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  background: rgba(0, 0, 0, 0.4);
  transform: translateY(-50%) translateX(-50%);
  z-index: 500;
  // 模态框样式
  .modal-style {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 980px;
    height: 640px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%) translateX(-50%);
    background: url("@/assets/imgs/modal/alert-modal.png") no-repeat center
      center;
    background-size: 100% 110%;
    // 模态框头部
    .header {
      position: relative;
      width: 60%;
      height: 20%;
      text-align: center;
      // border: 1px solid #fff;
      // 关闭按钮
      .el-icon-close {
        position: absolute;
        top: -10%;
        right: -25px;
        font-size: 32px;
        font-weight: bold;
        color: rgb(241, 169, 15);
        &:hover {
          transition: all 120ms linear;
          cursor: pointer;
          transform: scale(1.1);
          color: rgb(241, 68, 15);
        }
      }
    }
    // 模态框内容
    .modal-content {
      // border: 1px solid #fff;
      padding: 10px 0;
      width: 60%;
      height: 30%;
      min-width: 140px;
      overflow: auto;
    }
    // 模态框底部
    .modal-footer {
      // border: 1px solid #fff;
      width: 60%;
      height: 60px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      button {
        cursor: pointer;
      }
    }
  }
}

// 上传样式
::v-deep .el-upload--picture-card {
  background: transparent;
}
</style>
