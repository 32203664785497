<template>
  <i
    class="web-icon"
    :class="`el-icon-${iconName}`"
    :style="` font-size:${iconSize}px; color:${iconColor}`"
  ></i>
</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      required: true,
    },
    iconSize: {
      type: [String, Number],
      default: "16px",
    },
    iconColor: {
      type: [String],
      default: "#fff",
    },
  },
};
</script>
<style lang="scss"></style>
